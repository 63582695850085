<template>
  <div>
    <div class="center-horizontal newsletter-registration-form box--unit form--md">
      <h4>{{ $t('landing.newsletter.title') }}</h4>
      <div class="spacer--s"/>
      <template v-if="model.newsletter.success === null">
        <v-text-field
            v-model="user.email"
            label="Email"
            variant="underlined"
            :hint="$t('landing.newsletter.hint')"
            placeholder="name@domain.co"
        />
        <div class="spacer"/>
        <v-btn
            color="var(--s-primary)"
            class="input-button dark-color form--s"
            @click="registerNewsletter(user.email)"
        >
          {{ $t('signup') }}
        </v-btn>
      </template>
      <template v-else>
        <span v-if="model.newsletter.success" style="color: var(--w-good)">{{ $t('newsletterSuccessRegister') }}</span>
        <span v-else style="color: var(--w-danger)">{{ $t('newsletterFailureRegister') }}</span>
      </template>
    </div>
  </div>
</template>
<script>
import {registerNewsletter} from "./newsletterController.js";

export default {
  data(){
    return{
      user: {
        email: null
      },
      model: {
        newsletter: {
          success: null
        }
      }
    }
  },
  methods: {
    async registerNewsletter(email){
      const result = await registerNewsletter(email);
      this.model.newsletter.success = result.success;
    }
  }
}
</script>
<style scoped lang="scss">
.newsletter-registration-form {
  max-width: calc(14rem + 12vw);
}
</style>