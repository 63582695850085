<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
const { loginWithRedirect } = useAuth0();

async function login() {
  await loginWithRedirect();
}
</script>

<template>
  <div class="main-div-vertical encouraging-academy">
    <h1 class="hide-seo-friendly">
      Encouraging Academy Ermutigungs Akademie Mental Health Mentale Gesundheit Digitale Kurse Digital Courses
      Personality Persönlichkeitsentwicklung
    </h1>
    <div class="fullscreen-section text-center">
      <!-- Background Graphics -->
      <div class="background-container">
        <div class="background"/>
      </div>
      <div class="page-section-background page-section-min-padding">
        <v-container class="center">
          <v-row>
            <v-col v-if="$vssWidth > 960">
              <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_sylviastark.webp"/>
            </v-col>
            <v-col>
              <div class="center">
                <div>
                  <Icon
                      color="var(--s-primary)"
                      size="calc(5.25rem + 2.35vw + 1.35vh)"
                  />
                </div>
                <div class="spacer"/>
                <div>
                  <h1>{{ tenantName }}</h1>
                </div>
                <div>
                  <h5 v-html="$t('slogan') "/>
                </div>
                <div class="spacer"/>
                <v-container class="no-padding-margin">
                  <v-row>
                    <v-col>
                      <v-btn
                          color="var(--s-primary)"
                          class="input-button dark-color form--s"
                          @click="login"
                      >
                        {{ $t('toSignin') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="page-section-adjustable text-center">
      <v-container>
        <v-row>
          <v-col>
            <div class="center page-section-min-padding">
              <v-img class=" icon--giant center-horizontal" src="/img/tenant/encouraging_academy_glasses.webp"/>
              <h3>Newsletter</h3>
              <div class="spacer--s"/>
              <h5>{{ $t('landing.newsletter.desc') }}</h5>

              <div class="spacer"/>

              <NewsletterRegistrationComponent/>
            </div>
          </v-col>
          <v-col v-if="$vssWidth > 960">
            <v-container class="no-padding-margin">
              <v-row>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person1.webp"/>
                </v-col>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person2.webp"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person3.webp"/>
                </v-col>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person4.webp"/>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="text-center">
      <LegalDisclosure/>
    </div>
  </div>
</template>
<script>
import Icon from "@/tenant/TenantIcon.vue";
import LegalDisclosure from "@/views/LegalDisclosure.vue";
import NewsletterRegistrationComponent from "@/modules/newsletter/NewsletterRegistrationComponent.vue";

export default {
  components: {
    Icon,
    LegalDisclosure,
    NewsletterRegistrationComponent
  },
  data() {
    return {
      tenantName: process.env.VUE_APP_TENANT_NAME,
      model: {
        selectedLecturer: "sylviastark",
      }
    }
  }
}
</script>
<style scoped lang="scss">
.main-div-vertical.encouraging-academy {
  background-color: var(--background-secondary-light);
}

.encouraging-academy .background-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: visible;
}

.encouraging-academy .background {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 120vw;
  height: auto;
  min-height: 100%;
  background-image: url('/public/img/tenant/encouraging_academy_landingbackground.webp');
  background-size: cover;
  background-position: right top;
  overflow: visible;
  opacity: 40%;
  z-index: 1;
  pointer-events: none;
}

.encouraging-academy .feature-item {
  background-color: #faf9f7;
  margin: calc(.5rem + .5vw);
  border-radius: 3px;
  border: solid .5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 10px 10px 0px 0px #DFBF6A60;
}

.encouraging-academy .lecturer-item {
  max-width: calc(14rem + 16vw);
}

@media screen and (max-width: 1200px) {
  .encouraging-academy .background-container {
    opacity: .35;
  }
}

</style>